@import "../../../styles/style-config";
@import "../../../styles/style-functions";

.error-load-product {
  text-align: center;
  text-transform: uppercase;

  p {
    margin: 0 0 25px 0;
    font-weight: 500;

    span {
      font-size: larger;
      color: $color-highlight;
    }
  }

  .refresh {
    cursor: pointer;
    height: 60px;
    width: 30px;
    border-width: 6px solid $color-highlight;
    margin-right: auto;
    margin-left: auto;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.products-list {
  overflow: hidden;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 0 0 50px 0;

  @include mf-md {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, max-content));
    grid-gap: 30px;
    padding: initial;
    justify-content: center;
  }

  .product {
    margin: 10px 25px 40px 25px;

    @include mf-md {
      margin: 0;
    }

    &:hover .image img {
      border-radius: 50px;
      border-bottom-left-radius: 0;
    }

    &:hover .infos .buy svg:first-child {
      display: inherit;

    }

    &:hover .infos .out-of-stock svg {
      display: inherit;
      cursor: not-allowed;
    }

    .image {
      .sticker-promo {
        position: absolute;
        padding: 12px 15px;
        background-color: $color-highlight;
        color: white;
        font-weight: 800;
        border-bottom-right-radius: 15px;
      }

      img {
        width: 100%;
        height: 350px;
        object-fit: cover;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        @include transition(all ease-in-out 0.2s);

        @include mf-xs {
          height: 400px;
        }
        @include mf-md {
          width: 230px;
          height: 230px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .infos {
      margin: 10px 0;

      .name {
        font-weight: 500;
        line-height: 24px;
        max-width: 180px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: left;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }

        @include custom(750) {
          text-overflow: ellipsis;
          white-space: unset;
          max-width: 450px;
        }
      }

      .rangebottom {
        display: flex;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;
        }

        .right {
          display: flex;
          color: $color-highlight;
        }

      }

      .fromprice {
        font-size: small;
        margin-right: 4px;
      }

      .twoprices {
        display: flex;
      }

      .buy {
        float: right;
        width: 41px;
        height: 44px;
        position: relative;
        display: inline-block;
        overflow: hidden;

        svg {
          @include transition(all ease-in-out 0.2s);
        }

        svg:first-child {
          display: none;
          @include custom(600) {
            display: inline-block;
            position: absolute;
            top: unset;
            left: unset;
            right: -50px;
            z-index: unset;
            opacity: unset;
          }
        }

        svg:last-child {
          display: inline-block;
          position: absolute;
          top: 50px;
          left: 0;
          z-index: 4;

          @include opacity(0);

        }

        &:hover svg:last-child {
          @include opacity(0);
          display: block;
        }

        &:hover svg:first-child {
          top: 0;
          right: unset;
          @include opacity(1);
        }

        &:hover {
          cursor: pointer;
        }


      }

      /* When product is out of stock */
      .out-of-stock {
        float: right;
        width: 41px;
        height: 44px;
        position: relative;
        display: inline-block;
      }

      .out-of-stock svg {
        display: none;
      }

      .out-of-stock .info {
        visibility: hidden;
        width: 120px;
        background-color: $color-base;
        color: #fff;
        text-align: center;
        border-radius: 8px;
        padding: 5px 0;

        /* Position */
        position: absolute;
        z-index: 1;
        bottom: 110%;
        right: 2%;
        margin-left: -60px;
        visibility: visible;
      }
    }
  }

  .product-added {
    .image {
      width: 100%;
      height: 400px;
      object-fit: cover;
      background-color: $color-highlight-light;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      text-align: center;
      padding-top: 50px;

      @include mf-md {
        width: 230px;
        height: 230px;
      }

      img:first-child {
        display: none;
      }

      img {
        width: 230px;
        height: 230px;
        border: solid 4px #fff;
        border-radius: 50px;
        border-bottom-left-radius: 0;
        @include transition(all ease-in-out 0.2s);

        @include mf-md {
          width: 130px;
          height: 130px;
        }
      }
    }

    .infos .buy {
      svg:first-child {
        display: none;
      }

      svg:last-child {
        display: block;
        top: 0;
        @include opacity(1);
      }
    }
  }
}

.products-list-next {
  display: table;
  margin: 0 auto;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  background-color: $global-button-color-background;
  color: $global-button-color-text;
  border-radius: 20px;
  cursor: pointer;
  @include transition(all ease-in-out 0.2s);

  &:hover {
    background-color: $global-button-color-background-hover;
    color: $global-button-color-text-hover;
  }

  &:hover span {
    color: $global-button-color-text-span-hover;
  }

  p {
    font-weight: 500;

    span {
      color: $global-button-color-text-span;
      @include transition(color ease-in-out 0.2s);
    }
  }
}
