@import "../../styles/style-config.scss";
@import "../../styles/style-functions.scss";

.price-catalog {
  @include font-size(15px);
  font-weight: 700;
  color: $color-highlight;
}

.price-single-product {
  @include font-size(22px);
  font-weight: 800;
  color: $color-highlight;
}

.price-cart {
  @include font-size(18px);
  font-weight: 800;
  color: $color-highlight;
  padding-top: 8px;
}

.price-cart-summary {
  float: right;
}

.price-order {
  @include font-size(17px);
  font-weight: 600;
  line-height: 30px;
}

.my-account-h {
  @include font-size(18px);
  font-weight: 800;
  color: $color-highlight;
}

.my-account {
  @include font-size(13px);
}

.strikethrough {
  text-decoration: line-through;
}

.promo {
  padding-right: 10px;
  color: $color-base;
}
